import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CloseMenuSvg from "../../Components/Extra/Svg/CloseMenuSvg";
import OpenMenuSvg from "../../Components/Extra/Svg/OpenMenuSvg";
import OutSvg from "../../Components/Extra/Svg/OutSvg";
import SupportSvg from "../../Components/Extra/Svg/SupportSvg";
import translator from "../../Components/Extra/Translation/Translate";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import UtilsService from "../../Services/UtilsService";
import AccountMenu from "./MenuList";
import ItemListMobile from "./Nav/ItemListMobile";
import ThemeMobile from "./ThemeSwticher/ThemeMobile";
import { useAppDispatch } from "../../globalState/hook";
import useLanguage from "../../Services/Hooks/useLanguage";
import {
    ChangeAppLanguage,
    Language,
} from "../../globalState/reducers/Language.reducer";
import PachBoosterSvg from "../../Components/Extra/Svg/PachBoosterSvg";
import Notification from "../../Components/Extra/Notification/Notification";
import Dropdown from "../../Components/Extra/Dropdown/Dropdown";
import RankSvg from "../../Components/Extra/Svg/RankSvg";

const SideMobile = () => {
    const navigate = useNavigate();
    const { translate } = translator();
    const { user } = useAuthentication();
    const toggleExanded = (e: any) => {
        e.preventDefault();
    };

    const hangleCLick = (path: string, classname: string) => {
        navigate(path);
    };

    const hangleCLickBlank = (path: string, classname: string) => {
        window.open(path, "_blank");
    };

    const [checked, setChecked] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const { language } = useLanguage();

    const changeLanguage = (value: string) => {
        switch (value) {
            case "FR":
                dispatch(
                    ChangeAppLanguage({
                        language: Language.FR,
                    }),
                );
                break;
            case "EN":
                dispatch(
                    ChangeAppLanguage({
                        language: Language.EN,
                    }),
                );
                break;
            case "ES":
                dispatch(
                    ChangeAppLanguage({
                        language: Language.ES,
                    }),
                );
                break;

            default:
                break;
        }
    };
    return (
        <div className="mobile-menu !fixed md:hidden" id="id-mobile--menu">
            <div className="!bg-black !fixed flex justify-between h-[66px] !top-0 items-center !w-full -mr-3.5 right-[14px] px-2">
                <div className="w-[70%]">
                    <NavLink to="/" className="text-left">
                        <img
                            alt="Image"
                            className="w-[70%]"
                            src={UtilsService.getPulicImage(
                                "/dist/logo/Kalyssi-2.png",
                            )}
                        />
                    </NavLink>
                </div>
                <div className="w-[30%]">
                    <div className="flex items-center w-full justify-end">
                        <div className="w-10 h-10 mt-2 notif">
                            <Notification />
                        </div>
                        <div className="w-10 h-10 mt-2 profil">
                            <Dropdown />
                        </div>
                    </div>
                </div>
            </div>
            <div className="scrollable">
                <NavLink
                    to={`javascript:void(0)`}
                    className="mobile-menu-toggler"
                    onClick={(e) => toggleExanded(e)}
                >
                    <CloseMenuSvg />
                </NavLink>
                <ul className="scrollable__content py-2 relative">
                    <div className="my-2 ml-2 text-white intro-x">Manage</div>
                    <div className="side-nav__devider mdg-side-profile my-6"></div>
                    {AccountMenu.map((menu, i) => (
                        <ItemListMobile
                            key={i}
                            name={menu.name}
                            icon={menu.icon}
                            path={menu.path}
                            childs={menu.childs}
                        />
                    ))}
                    <li className="menu__devider my-1"></li>
                    <div className="my-2 ml-2 text-white intro-x">Finances</div>
                    <li>
                        <button
                            type="button"
                            className="bg-transparent border-none menu single-menu cursor-default"
                            onClick={() =>
                                hangleCLick(
                                    "/earning",
                                    "mobile-menu !fixed md:hidden",
                                )
                            }
                        >
                            <div className="menu__icon">
                                <RankSvg />
                            </div>
                            <div className="menu__title">Earning</div>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="bg-transparent border-none menu single-menu cursor-default"
                        >
                            <div className="menu__icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    width="24"
                                    height="24"
                                    className="text-white fill-white"
                                >
                                    <path d="M568.2 336.3c-13.12-17.81-38.14-21.66-55.93-8.469l-119.7 88.17h-120.6c-8.748 0-15.1-7.25-15.1-15.99c0-8.75 7.25-16 15.1-16h78.25c15.1 0 30.75-10.88 33.37-26.62c3.25-20-12.12-37.38-31.62-37.38H191.1c-26.1 0-53.12 9.25-74.12 26.25l-46.5 37.74L15.1 383.1C7.251 383.1 0 391.3 0 400v95.98C0 504.8 7.251 512 15.1 512h346.1c22.03 0 43.92-7.188 61.7-20.27l135.1-99.52C577.5 379.1 581.3 354.1 568.2 336.3zM279.3 175C271.7 173.9 261.7 170.3 252.9 167.1L248 165.4C235.5 160.1 221.8 167.5 217.4 179.1s2.121 26.2 14.59 30.64l4.655 1.656c8.486 3.061 17.88 6.095 27.39 8.312V232c0 13.25 10.73 24 23.98 24s24-10.75 24-24V221.6c25.27-5.723 42.88-21.85 46.1-45.72c8.688-50.05-38.89-63.66-64.42-70.95L288.4 103.1C262.1 95.64 263.6 92.42 264.3 88.31c1.156-6.766 15.3-10.06 32.21-7.391c4.938 .7813 11.37 2.547 19.65 5.422c12.53 4.281 26.21-2.312 30.52-14.84s-2.309-26.19-14.84-30.53c-7.602-2.627-13.92-4.358-19.82-5.721V24c0-13.25-10.75-24-24-24s-23.98 10.75-23.98 24v10.52C238.8 40.23 221.1 56.25 216.1 80.13C208.4 129.6 256.7 143.8 274.9 149.2l6.498 1.875c31.66 9.062 31.15 11.89 30.34 16.64C310.6 174.5 296.5 177.8 279.3 175z" />
                                </svg>
                            </div>
                            <div className="menu__title">
                                Borrowing
                                <span className="text-xs ml-1">
                                    (Coming Soon)
                                </span>
                            </div>
                        </button>
                    </li>
                    <li className="menu__devider my-1"></li>
                    <div className="my-2 ml-2 text-white intro-x">Supports</div>
                    <li>
                        <button
                            type="button"
                            className="bg-transparent border-none menu single-menu cursor-default"
                            onClick={() =>
                                hangleCLick(
                                    "/settings/referrals",
                                    "mobile-menu !fixed md:hidden",
                                )
                            }
                        >
                            <div className="menu__icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 1920 1792"
                                    className="mr-2"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M593 896q-162 5-265 128H194q-82 0-138-40.5T0 865q0-353 124-353q6 0 43.5 21t97.5 42.5T384 597q67 0 133-23q-5 37-5 66q0 139 81 256zm1071 637q0 120-73 189.5t-194 69.5H523q-121 0-194-69.5T256 1533q0-53 3.5-103.5t14-109T300 1212t43-97.5t62-81t85.5-53.5T602 960q10 0 43 21.5t73 48t107 48t135 21.5t135-21.5t107-48t73-48t43-21.5q61 0 111.5 20t85.5 53.5t62 81t43 97.5t26.5 108.5t14 109t3.5 103.5zM640 256q0 106-75 181t-181 75t-181-75t-75-181t75-181T384 0t181 75t75 181zm704 384q0 159-112.5 271.5T960 1024T688.5 911.5T576 640t112.5-271.5T960 256t271.5 112.5T1344 640zm576 225q0 78-56 118.5t-138 40.5h-134q-103-123-265-128q81-117 81-256q0-29-5-66q66 23 133 23q59 0 119-21.5t97.5-42.5t43.5-21q124 0 124 353zm-128-609q0 106-75 181t-181 75t-181-75t-75-181t75-181t181-75t181 75t75 181z"
                                    />
                                </svg>
                            </div>
                            <div className="menu__title">
                                {translate("Profil", "Affiliation")}
                            </div>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="bg-transparent border-none menu single-menu cursor-default"
                            onClick={() =>
                                hangleCLick(
                                    "/loyalty",
                                    "mobile-menu !fixed md:hidden",
                                )
                            }
                        >
                            <div className="menu__icon">
                                <PachBoosterSvg />
                            </div>
                            <div className="menu__title">
                                {translate("Admin_Affiliation", "Loyalty")}
                            </div>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="bg-transparent border-none menu single-menu cursor-default"
                            onClick={() =>
                                hangleCLick(
                                    "/governances",
                                    "mobile-menu !fixed md:hidden",
                                )
                            }
                        >
                            <div className="menu__icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="text-white fill-white"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path d="M18 13h-.68l-2 2h1.91L19 17H5l1.78-2h2.05l-2-2H6l-3 3v4c0 1.1.89 2 1.99 2H19c1.1 0 2-.89 2-2v-4l-3-3zm-1-5.05l-4.95 4.95-3.54-3.54 4.95-4.95L17 7.95zm-4.24-5.66L6.39 8.66c-.39.39-.39 1.02 0 1.41l4.95 4.95c.39.39 1.02.39 1.41 0l6.36-6.36c.39-.39.39-1.02 0-1.41L14.16 2.3c-.38-.4-1.01-.4-1.4-.01z" />
                                </svg>
                            </div>
                            <div className="menu__title">
                                {translate("Governance", "Governance")}
                            </div>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="bg-transparent border-none menu single-menu cursor-default"
                            onClick={() =>
                                hangleCLick(
                                    "/settings/general",
                                    "mobile-menu !fixed md:hidden",
                                )
                            }
                        >
                            <div className="menu__icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    icon-name="settings"
                                    data-lucide="settings"
                                    className="lucide lucide-settings"
                                >
                                    <path d="M12.22 2h-.44a2 2 0 00-2 2v.18a2 2 0 01-1 1.73l-.43.25a2 2 0 01-2 0l-.15-.08a2 2 0 00-2.73.73l-.22.38a2 2 0 00.73 2.73l.15.1a2 2 0 011 1.72v.51a2 2 0 01-1 1.74l-.15.09a2 2 0 00-.73 2.73l.22.38a2 2 0 002.73.73l.15-.08a2 2 0 012 0l.43.25a2 2 0 011 1.73V20a2 2 0 002 2h.44a2 2 0 002-2v-.18a2 2 0 011-1.73l.43-.25a2 2 0 012 0l.15.08a2 2 0 002.73-.73l.22-.39a2 2 0 00-.73-2.73l-.15-.08a2 2 0 01-1-1.74v-.5a2 2 0 011-1.74l.15-.09a2 2 0 00.73-2.73l-.22-.38a2 2 0 00-2.73-.73l-.15.08a2 2 0 01-2 0l-.43-.25a2 2 0 01-1-1.73V4a2 2 0 00-2-2z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                            </div>
                            <div className="menu__title">
                                {translate("Profil", "Setting_Security")}
                            </div>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="bg-transparent border-none menu single-menu cursor-default"
                            onClick={() =>
                                hangleCLickBlank(
                                    "https://kalypay.com/faq/",
                                    "mobile-menu !fixed md:hidden",
                                )
                            }
                        >
                            <div className="menu__icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    className="bi bi-exclamation-octagon"
                                    viewBox="0 0 16 16"
                                >
                                    {" "}
                                    <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z" />{" "}
                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />{" "}
                                </svg>
                            </div>
                            <div className="menu__title">
                                {translate("Setting_Option", "Help_Center")}
                            </div>
                        </button>
                    </li>
                    <li className="menu__devider my-1"></li>
                    <li className="">
                        <div className="menu justify-between mb-2">
                            <div className="inline-flex items-center">
                                <div className="m-4">
                                    <ThemeMobile></ThemeMobile>
                                </div>
                            </div>
                            <div>
                                <select
                                    id="countries"
                                    className="bg-black text-white border border-gray-300 text-sm rounded-lg focus:ring-[#daa520] focus:border-[#daa520] block w-20 p-2.5"
                                    onChange={(e) =>
                                        changeLanguage(e.target.value)
                                    }
                                    defaultValue={language.toUpperCase()}
                                >
                                    <option value="FR">FR</option>
                                    <option value="EN">EN</option>
                                    <option value="ES">ES</option>
                                </select>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="!bg-black !fixed !bottom-0 !top-auto !w-full -mr-3.5 right-[14px]">
                <div className="bg-black w-full py-0 px-3 text-white flex items-center space-x-2">
                    <div className="w-1/5">
                        <NavLink
                            to="/dashboard"
                            className="block text-[10px] text-center leading-3 cursor-default"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                icon-name="monitor"
                                data-lucide="monitor"
                                className="lucide lucide-monitor m-auto mb-2"
                            >
                                <rect
                                    x="2"
                                    y="3"
                                    width="20"
                                    height="14"
                                    rx="2"
                                    ry="2"
                                ></rect>
                                <line x1="8" y1="21" x2="16" y2="21"></line>
                                <line x1="12" y1="17" x2="12" y2="21"></line>
                            </svg>
                            {/* {translate("Dashboard", "Dashboard")} */}
                            Dashboard
                        </NavLink>
                    </div>
                    <div className="w-1/5">
                        <NavLink
                            to="/wallets"
                            className="block text-[10px] text-center leading-3 cursor-default"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                icon-name="wallet"
                                data-lucide="wallet"
                                className="lucide lucide-wallet m-auto mb-2"
                            >
                                <path d="M20 12V8H6a2 2 0 0 1-2-2c0-1.1.9-2 2-2h12v4"></path>
                                <path d="M4 6v12c0 1.1.9 2 2 2h14v-4"></path>
                                <path d="M18 12a2 2 0 0 0-2 2c0 1.1.9 2 2 2h4v-4h-4z"></path>
                                <line x1="9" y1="14" x2="15" y2="14"></line>
                            </svg>
                            {/* {translate("Wallets", "My_Wallets")} */}
                            Wallets
                        </NavLink>
                    </div>
                    <div className="w-1/5">
                        <NavLink
                            to={`javascript:void(0)`}
                            onClick={(e) => toggleExanded(e)}
                            className="block text-[10px] text-center cursor-default !right-auto !relative !top-auto mobile-menu-toggler bottom-[10px] bg-black rounded-[50%] p-[10px]"
                        >
                            <img
                                src={UtilsService.getPulicImage(
                                    "/dist/logo/Kalyssi.png",
                                )}
                                className="w-[50px] m-auto"
                                alt=""
                            />
                        </NavLink>
                    </div>
                    <div className="w-1/5">
                        <NavLink
                            to="/earning"
                            className="block text-[10px] text-center leading-3 cursor-default"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                icon-name="pie-chart"
                                data-lucide="pie-chart"
                                className="lucide lucide-pie-chart block m-auto mb-2"
                            >
                                <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                                <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                            </svg>
                            Earning
                        </NavLink>
                    </div>
                    <div className="w-1/5">
                        <NavLink
                            to="/transactions"
                            className="block text-[10px] text-center leading-3 cursor-default"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                className="bi bi-card-list m-auto mb-2"
                                viewBox="0 0 16 16"
                            >
                                {" "}
                                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />{" "}
                                <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />{" "}
                            </svg>
                            Transactions
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideMobile;
