import React, { useEffect, useRef, useState } from "react";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import translator from "../../Components/Extra/Translation/Translate";
import {
    ModalContext,
    ModalProvider,
} from "../../Components/Extra/Modal/ModalContext";
import AppAuthentication from "../../Services/Authentication/AppAuthentication";
import { useAppProvider } from "./AppProvider";
import PinInput from "react-pin-input";
import AppProfileService from "../../Services/User/Profile/AppProfile";
import alert_message from "../../Services/Alert/AlertMessage";
import { Navigate } from "react-router-dom";
import RoutesNames from "../../Services/RoutesNames";
import ReCAPTCHA from "react-google-recaptcha";

const CHECK_INTERVAL = 1000 * 60 * 1;

const useCountDown = (timer: number) => {
    const [timeLeft, setTimeLeft] = useState(timer); // Initialisation à 60 secondes
    const [isRunning, setIsRunning] = useState(true); // Pour savoir si le timer est actif

    useEffect(() => {
        let timer: any;
        if (isRunning && timeLeft > 0) {
            // Décrémentation chaque seconde
            timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
        } else if (timeLeft === 0) {
            // Stoppe le timer à 0
            setIsRunning(false);
        }

        return () => clearInterval(timer); // Nettoie l'intervalle
    }, [isRunning, timeLeft]);

    const restartTimer = () => {
        setTimeLeft(60); // Réinitialise à 60 secondes
        setIsRunning(true); // Relance le timer
    };

    return { timeLeft, isRunning, setIsRunning, restartTimer };
};

export const VerifyPhoneForm = () => {
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);
    const { processing, setProcessing, finalizeProcessing } = useAppProvider();
    const [code, setCode] = useState<string>("");
    const { getInformationAsync } = AppAuthentication();
    const { user } = useAuthentication();
    const { timeLeft, isRunning, restartTimer } = useCountDown(60);

    const recaptchaRef = useRef<ReCAPTCHA | null>(null);
    const [token, setToken] = useState<string | null>(null);

    const verifyPhone = () => {
        setProcessing(true);
        const verifyPhone = AppProfileService.verifyPhone(code);
        alert_message
            .promise(
                translate("PhoneVerification", "Verify_In_Process"),
                verifyPhone,
                translate("PhoneVerification", "Verified_With_Success"),
            )
            .then((data) => {
                handleModal();
            })
            .finally(() => {
                finalizeProcessing();
                getInformationAsync(true);
            });
    };
    const resendCode = () => {
        if (!token) return;
        setProcessing(true);
        const verifyPhone = AppProfileService.resendVeridicationCode(token);
        restartTimer();
        alert_message
            .promise(
                translate("PhoneVerification", "Send_code_in_progress"),
                verifyPhone,
                translate("PhoneVerification", "Code_sent_with_success"),
            )
            .finally(() => {
                finalizeProcessing();
                getInformationAsync(true);
            });
    };

    if (user?.is_phone_verified) return <Navigate to={RoutesNames.Dashboard} />;

    return (
        <div className="m-auto w-full rounded-md flex justify-center items-center bg-white-log px-5 py-8 shadow-md sm:w-3/4 sm:px-8 lg:w-2/4 xl:bg-transparent xl:p-0 xl:shadow-none mdg-signup-box">
            <div className="relative">
                <h2 className="intro-x text-center text-2xl font-bold xl:text-left xl:text-3xl">
                    {translate("PhoneVerification", "verify_your_phone")}
                </h2>
                <div className="intro-x mt-8">
                    <div className="intro-x mt-2 text-center text-slate-400 ">
                        {translate("PhoneVerification", "Indication")}
                    </div>
                    <div className="flex justify-center items  w-full">
                        <PinInput
                            length={6}
                            initialValue={code}
                            // secret
                            onComplete={(value, index) => {
                                setCode(value);
                            }}
                            type="numeric"
                            inputMode="number"
                            style={{ padding: "10px" }}
                            inputStyle={{
                                borderColor: "#1b253b",
                                borderRadius: "10px",
                            }}
                            inputFocusStyle={{ borderColor: "#053B3B" }}
                            autoSelect={true}
                            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                        />
                    </div>
                    <div className="w-full justify-center recap">
                        {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
                            <>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={
                                        process.env.REACT_APP_RECAPTCHA_SITE_KEY
                                    }
                                    onChange={(t) => setToken(t)}
                                />
                            </>
                        )}
                    </div>
                    {isRunning && (
                        <div className="intro-x mt-2 text-center text-slate-400 ">
                            Renvoyer le code dans {timeLeft} secondes
                        </div>
                    )}
                    <div className="flex justify-center items  w-full">
                        {!isRunning && (
                            <button
                                type="button"
                                className="btn btn-perfect mt-2 mr-2"
                                onClick={() => resendCode()}
                                disabled={processing || !token}
                            >
                                {translate("PhoneVerification", "ResendCode")}
                            </button>
                        )}
                        <button
                            type="button"
                            className="btn btn-perfect mt-2 mr-2"
                            onClick={() => verifyPhone()}
                            disabled={code.length !== 6 || processing || !token}
                        >
                            {translate("PhoneVerification", "Verify")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const PhoneChecker = () => {
    const { translate } = translator();

    const { user } = useAuthentication();
    const { handleModal } = React.useContext(ModalContext);

    useEffect(() => {
        if (!user?.is_phone_verified) {
            handleModal(<VerifyPhoneForm />);
        }
    }, [user?.is_phone_verified]);

    return <></>;
};

export default function AppPhoneChecker({
    children,
}: {
    children: React.ReactNode;
}) {
    return <>{children}</>;
}
